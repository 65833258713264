import { generatePDF } from "../api/pdf";

const removeVWO = (newDocument: Document) => {
  // Step 3: Remove the script tag from the new document
  var scriptTags = newDocument.querySelectorAll("script");

  for (let index = 0; index < scriptTags.length; index++) {
    const el = scriptTags[index];
    if (
      el.id === "vwoCode" ||
      el.src.startsWith("https://dev.visualwebsiteoptimizer.com")
    ) {
      el.remove();
    }
  }
};

export const downloadPDF = async (title?: string | null, url?: string) => {
  let res = "";
  const fileName = encodeURIComponent(
    `${title || "content"}`.replace(/[^a-z0-9]/gi, "_").toLowerCase()
  );

  const allCSS = [...document.styleSheets]
    .map((styleSheet) => {
      try {
        return [...styleSheet.cssRules].map((rule) => rule.cssText).join("");
      } catch (e) {
        console.log(
          "Access to stylesheet %s is denied. Ignoring...",
          styleSheet.href
        );
      }
    })
    .filter(Boolean)
    .join("\n");

  if (!url?.length) {
    // Step 1: Create a new document
    var newDocument = document.implementation.createHTMLDocument();

    // Step 2: Set the HTML content of the new document to the current page's content
    newDocument.documentElement.innerHTML = document.documentElement.innerHTML;

    removeVWO(newDocument);

    const finalHTML = `${newDocument.documentElement.innerHTML}<style>${allCSS}</style>`;

    const removedFade = finalHTML.replace(/opacity: 0/g, "opacity: 1");

    res = await generatePDF({ html: removedFade, fileName });
  } else {
    const pageRes = await fetch(url);
    const pageText = await pageRes.text();

    // Initialize the DOM parser
    const parser = new DOMParser();

    // Parse the text
    const doc = parser.parseFromString(pageText, "text/html");

    removeVWO(doc);

    res = await generatePDF({ html: doc.documentElement.innerHTML.replace(/opacity:0/g, "opacity: 1"), fileName });
  }

  var link = document.createElement("a");
  link.href = res.startsWith("https") ? res : `https://${res}`;
  link.target = "_blank";
  link.download = `${fileName}.pdf`;
  link.click();
};
